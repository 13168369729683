import React from 'react'
import DemoBgDesktop from '../assets/images/backgrounds/demo-bg-desktop.svg'
import DemoBgMobile from '../assets/images/backgrounds/demo-bg-mobile.svg'
import { quotes } from '../components/blocks/QuoteBlock'
import { SmallQuoteBlock } from '../components/blocks/SmallQuoteBlock'
import BookForm from '../components/form/BookForm'
import Layout from '../components/Layout'
import Box from '../components/primitives/Box'
import Br from '../components/system/Br'

export default function BookPage() {
  return (
    <Layout
      title="Get your free copy of Build: Elements of an Effective Software Organization"
      description="Give us your shipping address and we’ll send you a free paperback copy of our book."
      isNew
      variant="dark"
    >
      <Box
        backgroundImage={{
          xs: `url(${DemoBgMobile})`,
          lg: `url(${DemoBgDesktop})`,
        }}
        backgroundColor="black"
        backgroundSize={{ xs: '520px 990px', lg: '1441px 1205px' }}
        backgroundRepeat="no-repeat"
      >
        <Box paddingTop={120} className="hero-block" />
        <Box
          display="flex"
          flexDirection={{ xs: 'column', lg: 'row' }}
          alignItems="center"
          position="relative"
          maxWidth={1200}
          marginX="auto"
          paddingTop={{ xs: 0, lg: 64 }}
          paddingX={{ xs: 32, lg: 64 }}
        >
          <BackgroundBox />
          <Box zIndex={1} marginRight={{ xs: 0, lg: 32, demoWide: 64 }}>
            <TextBlock />
            <DesktopBlock />
          </Box>
          <FormBlock />
          <MobileBlock />
        </Box>
      </Box>
    </Layout>
  )
}

function BackgroundBox() {
  return (
    <Box
      display={{ xs: 'none', lg: 'block' }}
      position="absolute"
      top={0}
      left={0}
      // @ts-ignore custom relative bg width
      width="75%"
      // @ts-ignore custom relative bg width
      height="80%"
      borderRadius={32}
      borderStyle="solid"
      borderColor="blockLightBorder"
      css={`
        background: linear-gradient(
          180deg,
          rgba(149, 136, 255, 0.25) 0%,
          rgba(72, 54, 219, 0.21) 55%,
          rgba(26, 21, 62, 0) 100%
        );
        backdrop-filter: blur(15px);
      `}
    />
  )
}

function TextBlock() {
  return (
    <Box color="white">
      <Box.h1 font="h1" fontSize={{ xs: 56, xsm: 56, sm: 56, lg: 56 }}>
        Get your free <Br />
        copy of Build
      </Box.h1>
      <Box marginTop={40} font="large">
        We’d love to send you a paperback copy of our book, Build: Elements of
        an Effective Software Organization. Simply fill out the form, and we’ll
        take care of the rest.
      </Box>
      <Box marginTop={20} font="large">
        Please note that we’re currently only able to send books to the United
        States, Canada, Europe, Australia, and New Zealand.
      </Box>
    </Box>
  )
}

function DesktopBlock() {
  return (
    <Box display={{ xs: 'none', lg: 'block' }} paddingBottom={64}>
      <Divider />
      <Box marginTop={40} maxWidth={532}>
        <Quote />
      </Box>
    </Box>
  )
}

function FormBlock() {
  return (
    <Box
      alignSelf={{ xs: 'auto', lg: 'flex-start' }}
      flexShrink={0}
      width={{ xs: '100%', lg: 588 }}
      maxWidth={588}
      backgroundColor="white"
      marginTop={{ xs: 40, lg: 0 }}
      padding={32}
      borderRadius={24}
      zIndex={1}
    >
      <BookForm />
    </Box>
  )
}

function MobileBlock() {
  return (
    <Box display={{ xs: 'block', lg: 'none' }} width="100%">
      <Box display="flex" justifyContent="center" marginTop={40}>
        <Quote />
      </Box>
      <Divider />
    </Box>
  )
}

function Divider() {
  return (
    <Box
      marginTop={40}
      width="100%"
      // @ts-ignore custom height
      height="1px"
      backgroundColor="blockLightBorder"
    />
  )
}

function Quote() {
  return (
    <Box maxWidth={588}>
      <SmallQuoteBlock quote={quotes.lucaR} isDark />
    </Box>
  )
}
