import { navigate } from 'gatsby'
import React, { useRef, useState } from 'react'
import { validateWorkEmailInput } from '../../formUtils'
import { HSFormField, sendFormToHS } from '../../hubspot'
import Box from '../primitives/Box'
import Row from '../primitives/Row'
import CTABtn from './CTAButton'
import FormCheckboxContainer from './FormCheckboxContainer'

export function BookForm() {
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [address, setAddress] = useState('')
  const [zip, setZip] = useState('')
  const [city, setCity] = useState('')
  const [state, setState] = useState('')
  const [country, setCountry] = useState('')
  const [phone, setPhone] = useState('')
  const [loading, setLoading] = useState(false)
  const [errorMsg, setErrorMsg] = useState<string | null>(null)

  const emailRef = useRef<HTMLInputElement>(null)

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    setLoading(true)
    setErrorMsg(null)
    try {
      const formId = '1942c271-2757-4937-b214-21a969385435'
      const fields: HSFormField[] = [
        { name: 'firstname', value: firstName },
        { name: 'lastname', value: lastName },
        { name: 'email', value: email },
        { name: 'address', value: address },
        { name: 'zip', value: zip },
        { name: 'city', value: city },
        { name: 'state', value: state },
        { name: 'country', value: country },
        { name: 'phone', value: phone },
      ]
      const res = await sendFormToHS(fields, formId)
      if (res.status === 200) {
        await navigate('/thank-you/?origin=book&target=qualified')
      } else {
        setErrorMsg(
          'There was an error submitting the form. Please try again. If the issue persists, contact us at books@swarmia.com.',
        )
        window.Sentry?.captureException(JSON.stringify(res))
      }
    } catch (error) {
      window.Sentry?.captureException(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Box className="cta-form">
      <Box.form className="form" onSubmit={onSubmit}>
        <Row space={16}>
          <input
            type="text"
            placeholder="First name"
            required
            onChange={e => setFirstName(e.target.value)}
            value={firstName}
          />
          <input
            type="text"
            placeholder="Last name"
            required
            onChange={e => setLastName(e.target.value)}
            value={lastName}
          />
        </Row>
        <input
          ref={emailRef}
          className="email"
          type="email"
          placeholder="Work email"
          minLength={5}
          required
          onChange={e => {
            setEmail(e.target.value)
            validateWorkEmailInput(e.target)
          }}
          value={email}
        />
        <input
          type="text"
          placeholder="Street address (& company name if office address)"
          required
          onChange={e => setAddress(e.target.value)}
          value={address}
        />
        <Row space={16}>
          <input
            type="text"
            placeholder="Zip code"
            required
            onChange={e => setZip(e.target.value)}
            value={zip}
          />
          <input
            type="text"
            placeholder="City"
            required
            onChange={e => setCity(e.target.value)}
            value={city}
          />
        </Row>
        <Row space={16}>
          <input
            type="text"
            placeholder="State"
            onChange={e => setState(e.target.value)}
            value={state}
          />
          <input
            type="text"
            placeholder="Country"
            required
            onChange={e => setCountry(e.target.value)}
            value={country}
          />
        </Row>
        <input
          type="text"
          placeholder="Phone number (for the delivery)"
          required
          onChange={e => setPhone(e.target.value)}
          value={phone}
        />
        <FormCheckboxContainer>
          <input type="checkbox" id="consent" required />
          <label htmlFor="consent">
            I agree to receive email communication in accordance with
            Swarmia&#8217;s{' '}
            <a href="/privacy/" target="_blank">
              Privacy Policy
            </a>
            .
          </label>
        </FormCheckboxContainer>
        <Row display="relative">
          <CTABtn className="primary" disabled={loading}>
            Request your copy
          </CTABtn>
        </Row>
        {errorMsg && (
          <Row paddingTop={24}>
            <Error message={errorMsg} />
          </Row>
        )}
      </Box.form>
    </Box>
  )
}

function Error({ message }: { message: string }) {
  return (
    <Box
      backgroundColor="errorRed"
      color="white"
      paddingX={20}
      paddingY={12}
      borderRadius={12}
    >
      {message}
    </Box>
  )
}

export default BookForm
